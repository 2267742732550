<template>
  <p-container>
    <div>
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="词云图">
          <div class="filter-wrap">
            <div class="filter-item parent-field">
              <div class="title">一级领域:</div>
              <a-select placeholder="请选择一级领域" style="width: 160px" @change="parentFieldChange">
                <a-select-option value="">请选择一级领域</a-select-option>
                <a-select-option
                  v-for="parent in parentFields"
                  :value="parent"
                  :key="parent"
                >{{ parent }}</a-select-option>
              </a-select>
            </div>
            <div class="filter-item child-field">
              <div class="title">二级领域:</div>
              <a-select placeholder="请选择二级领域" style="width: 160px" @change="childFieldChange">
                <a-select-option value="">请选择二级领域</a-select-option>
                <a-select-option
                  v-for="child in childFields"
                  :value="child"
                  :key="child"
                >{{ child }}</a-select-option>
              </a-select>
            </div>
            <div class="filter-item 2019-select">
              <a-checkbox @change="beforeSelectChange">2019</a-checkbox>
            </div>
            <div class="filter-item 2020-select">
              <a-checkbox @change="nowSelectChange">2020</a-checkbox>
            </div>
            <a-button type="primary" @click="confirmSearch">确定</a-button>
          </div>
          <div class="ciyun">
            <wordcloud
              :data="ciyun"
              nameKey="name"
              valueKey="value"
              :color="myColors"
              :showTooltip="true"
              :wordClick="wordClickHandler"
              :fontSize="[10,40]"
            ></wordcloud>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="技术领域分析">
          <div class="filter-wrap">
            <div class="filter-item parent-field">
              <div class="title">一级领域:</div>
              <a-select
                placeholder="请选择一级领域"
                style="width: 160px"
                @change="fieldChangeTab2( $event, 1)"
              >
                <a-select-option value="">请选择一级领域</a-select-option>
                <a-select-option
                  v-for="parent in parentFields"
                  :value="parent"
                  :key="parent"
                >{{ parent }}</a-select-option>
              </a-select>
            </div>
            <div class="filter-item child-field">
              <div class="title">二级领域:</div>
              <a-select placeholder="请选择二级领域" style="width: 160px" @change="fieldChangeTab2">
                <a-select-option value="">请选择二级领域</a-select-option>
                <a-select-option
                  v-for="child in childFields"
                  :value="child"
                  :key="child"
                >{{ child }}</a-select-option>
              </a-select>
            </div>
          </div>
          <div class="schema-wrap" style="width:100%;height:800px" ref="schemaWrap"></div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="完成人和完成单位分析">
          <div class="filter-wrap">
            <div class="filter-item parent-field">
              <a-select placeholder="请选择搜索项" style="width: 160px" @change="changeSearchType">
                <a-select-option :value="1">完成人</a-select-option>
                <a-select-option :value="2">完成单位</a-select-option>
              </a-select>
              <a-input-search placeholder="搜索" enter-button="搜索" size="default" @search="onSearch" />
            </div>
          </div>
          <div class="schema-wrap" style="width:100%;height:800px" ref="searchSchemaWrap"></div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </p-container>
</template>
<script src="../../../../echarts-wordcloud-master/dist/echarts-wordcloud.js"></script>
<script>
import {
  getcy,
  getField,
  getChildField,
  getFieldSearch,
  getFieldSchema,
  searchFinishPerson,
} from "../../../../api/result";
import wordcloud from "vue-wordcloud";
import * as echarts from "echarts";

export default {
  // name: 'warpTitle',
  name: "ap",
  components: {
    wordcloud,
  },
  methods: {
    async onSearch(val) {
      if (!val) {
        this.$message.warning("请输入搜索关键字");
        return;
      }

      const { data } = await searchFinishPerson({
        searchType: this.searchType,
        search: val,
      });
      const { seriesData, links } = this.normalSchemaData(data);

      const dom = this.$refs.searchSchemaWrap;
      this.initSchema(seriesData, links, dom);
    },
    changeSearchType(val) {
      this.searchType = val;
    },
    async fieldChangeTab2(val, isParent) {
      
      if (val === '') {
        this.getFieldData(2)
        return
      }
      const key = isParent === 1 ? "curParentField" : "curChildField";
      if (isParent === 1) {
        getChildField({ search: val }).then((res) => {
          this.childFields = res.data;
        });
      }

      const res = await getFieldSchema({ [key]: val });
      const data = res.data;

      const { seriesData, links } = this.normalSchemaData(data);

      const dom = this.$refs.schemaWrap;
      this.initSchema(seriesData, links, dom);
    },
    normalSchemaData(data) {
      this.category = [
        { name: "一级领域" },
        { name: "二级领域" },
        { name: "项目" },
      ];
      // 处理数据
      let id = 0;
      const seriesData = [
        {
          category: 0,
          id: "0",
          name: data.name,
          symbolSize: 40,
          label: {
            show: true,
          },
          value: "",
          x: -266,
          y: 299,
        },
      ];

      const links = [];

      data.children = data.children || [];

      data.children.forEach((item) => {
        const addX =
          Math.random() > 0.5
            ? this.randomNum(20, 25)
            : -this.randomNum(20, 25);
        const addY =
          Math.random() > 0.5
            ? this.randomNum(20, 25)
            : -this.randomNum(20, 25);
        const x = -266 + addX;
        const y = 299 + addY;

        id++;
        const pId = id;
        links.push({ source: id, target: 0, lineStyle: { width: 2 } });

        seriesData.push({
          category: 1,
          id: pId + "",
          name: item.name,
          symbolSize: 30,
          label: {
            show: true,
          },
          x,
          y,
          value: "",
        });

        item.children = item.children || [];
        if (!item.children.length) {
          this.category = [{ name: "二级领域" }, { name: "项目" }];
        }
        item.children.forEach((child) => {
          const addX =
            Math.random() > 0.5
              ? this.randomNum(5, 20)
              : -this.randomNum(5, 20);
          const addY =
            Math.random() > 0.5
              ? this.randomNum(5, 20)
              : -this.randomNum(5, 20);

          id++;
          links.push({ source: id, target: pId, lineStyle: { width: 1 } });

          seriesData.push({
            category: 2,
            id: id + "",
            name: child.name,
            symbolSize: 20,
            label: {
              show: false,
            },
            x: x + addX,
            y: y + addY,
            value: "",
          });
        });
      });
      return {
        seriesData: seriesData,
        links,
      };
    },
    initSchema(seriesData, links, dom) {
      const myCharts = echarts.init(dom);

      myCharts.setOption({
        title: {
          text: "",
          subtext: "Default layout",
          top: "bottom",
          left: "right",
        },
        tooltip: {},
        legend: [
          {
            // selectedMode: 'single',
            data: ["一级领域", "二级领域", "项目"],
          },
        ],
        animationDuration: 1500,
        animationEasingUpdate: "quinticInOut",
        series: [
          {
            name: "Les Miserables",
            type: "graph",
            layout: "none",
            data: seriesData,
            links: links,
            categories: this.category,
            roam: true,
            label: {
              position: "right",
              formatter: "{b}",
            },
            lineStyle: {
              color: "source",
              curveness: 0.3,
            },
            emphasis: {
              focus: "adjacency",
              lineStyle: {
                width: 10,
              },
            },
          },
        ],
      });
    },
    wordClickHandler(name, value, vm) {
      console.log("wordClickHandler", name, value, vm);
    },
    async parentFieldChange(val) {
      this.filterData.curParentField = val;
      this.filterData.curChildField = "";
      if (val === '') {
        this.getFieldData(2)
        return
      }
      alert(val);

      const res = await getChildField({ search: val });
      this.childFields = res.data;
    },
    childFieldChange(val) {
      this.filterData.curChildField = val;
    },
    beforeSelectChange(e) {
      this.filterData.beforeCheck = e.target.checked;
    },
    nowSelectChange(e) {
      this.filterData.nowCheck = e.target.checked;
    },
    async getFieldData(type) {
      console.log(type);
      const res = await getField({ type });

      if (type === 1) {
        this.parentFields = res.data;
      } else {
        this.childFields = res.data;
      }
    },
    async getcyData() {
      const res = await getcy();
      this.ciyun = res.data;
    },
    async confirmSearch() {
      console.log(this.filterData);
      const data = JSON.parse(JSON.stringify(this.filterData));
      data.beforeCheck = data.beforeCheck ? "2019" : "";
      data.nowCheck = data.nowCheck ? "2020" : "";
      const res = await getFieldSearch(data);
      this.ciyun = res.data;
    },
    randomNum: function (minNum, maxNum) {
      switch (arguments.length) {
        case 1:
          return Math.random() * minNum + 1;
        case 2:
          return Math.random() * (maxNum - minNum + 1) + minNum;
        default:
          return 0;
      }
    },
  },
  data() {
    return {
      parentFields: ["aaa", "bbb", "ccc"],
      childFields: ["cccaaa", "cccbbb", "cccccc"],
      filterData: {
        curParentField: "",
        curChildField: "",
        beforeCheck: false,
        nowCheck: false,
      },
      searchType: 1,
      myColors: ["#1f77b4", "#629fc9", "#94bedb", "#c9e0ef"],
      schemaData: {},
      category: [{ name: "一级领域" }, { name: "二级领域" }, { name: "项目" }],
      ciyun: [
        {
          list: [
            {
              name: "内容1",
              value: 26,
            },
            {
              name: "内容2",
              value: 19,
            },
            {
              name: "内容3",
              value: 18,
            },
            {
              name: "内容4",
              value: 16,
            },
            {
              name: "内容5",
              value: 15,
            },
            {
              name: "内容6",
              value: 9,
            },
            {
              name: "内容7",
              value: 9,
            },
            {
              name: "内容8",
              value: 9,
            },
            {
              name: "内容9",
              value: 6,
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.getcyData();
    this.getFieldData(1);
    this.getFieldData(2);
  },
};
</script>

<style scoped>
.sortList-title {
  font-size: 25px;
}
.wordCloud {
  width: 50% !important;
  height: 300px !important;
  /* background-color: red; */
}
.filter-wrap {
  display: flex;
  margin-bottom: 30px;
}
.filter-wrap .filter-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.filter-wrap .filter-item .title {
  margin-right: 10px;
}
.ant-input-search {
  margin-left: 10px;
}
</style>
